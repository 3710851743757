/* eslint-disable max-len */
import 'map.prototype.tojson';
import { CrossSellingGroup } from '@1po/1po-bff-fe-spec/generated/backoffice/cross_selling/response/CrossSellingReferencesFileResponse';
import {
  Reference as DHReference,
  ReferencePlateLine,
  ReferenceType,
} from '@1po/1po-bff-fe-spec/generated/catalog/references/dh/model/Reference';
import { ReuseReference } from '@1po/1po-bff-fe-spec/generated/catalog/references/dh/model/ReuseReference';
import { Reference as IAMReference } from '@1po/1po-bff-fe-spec/generated/catalog/references/iam/model/Reference';
import { ReferenceDiscount } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceDiscount';
import { ReferencePrice } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferencePrice';
import { ReferenceRepairPrice } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceRepairPrice';
import { ReferenceStock } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceStock';
import {
  ReferenceSource,
  ReferenceStockTradingDataRequestDetail,
} from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/request/GetReferencesStockRequest';
import { ReferenceTradingDataRequestDetail } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/request/GetReferenceTradingDataRequest';
import { ReferencesDiscountsResponse } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/response/ReferencesDiscountsResponse';
import {
  BACKOFFICE_CROSS_SELLING_GET_REFERENCES_FILE_RESPONSE,
  BACKOFFICE_CROSS_SELLING_GET_REFERENCES_RESPONSE,
  CHECK_IF_STOCKS_ARE_UP_TO_DATE_RESPONSE,
  GET_PLATES_FOR_REFERENCE_RESPONSE,
  GET_REFERENCES_DISCOUNTS_RESPONSE,
  GET_REFERENCES_PRICE_RESPONSE,
  GET_REFERENCES_REPAIR_PRICE_RESPONSE,
  GET_REFERENCES_STOCKS_RESPONSE,
  GET_REUSE_STOCK_RESPONSE,
  REMOVE_REFERENCES_DISCOUNTS_RESPONSE,
} from '@1po/1po-bff-fe-spec/generated/common/ResponseType';
import { createAction, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from 'app/AppStore';
import {
  CHECK_IF_STOCKS_ARE_UP_TO_DATE_REQUEST,
  DHReferenceLocal,
  GET_CROSS_SELLING_REFERENCES,
  GET_PLATES_FOR_REFERENCE_REQUEST,
  GET_REFERENCES_DISCOUNTS_REQUEST,
  GET_REFERENCES_PRICE_REQUEST,
  GET_REFERENCES_REPAIR_PRICES_REQUEST,
  GET_REFERENCES_STOCKS_REQUEST,
  GET_REUSE_STOCKS_REQUEST,
  IAMReferenceLocal,
  LocalTabType,
  ReferenceCatalog,
  ReferencePriceType,
  REFERENCES_NAMESPACE,
  ReferencesState,
} from 'domains/references/References.types';
import {
  FOUND,
  getData,
  hasData,
  LOADING,
  mergeObjectInMap,
  NO_DATA,
  NOT_FOUND,
  SEARCH_STATUS,
  SearchData,
} from 'utils';

// Saga actions
export const fetchReferencesPriceRequestSaga = createAction<{
  references: ReferenceTradingDataRequestDetail[];
}>(GET_REFERENCES_PRICE_REQUEST);
export const fetchReferencesPriceResponseSaga = createAction(GET_REFERENCES_PRICE_RESPONSE);

export const fetchCrossSellingReferencesRequestSaga = createAction<{
  references: string[];
  vehicleKey: string | undefined;
}>(GET_CROSS_SELLING_REFERENCES);
export const fetchCrossSellingReferencesResponseSaga = createAction(BACKOFFICE_CROSS_SELLING_GET_REFERENCES_RESPONSE);
export const fetchCrossSellingFileReferencesResponseSaga = createAction(
  BACKOFFICE_CROSS_SELLING_GET_REFERENCES_FILE_RESPONSE,
);
export const checkIfStocksAreStillUpToDateRequestSaga = createAction<{
  requestId: string;
  references: {
    referenceNumber: string;
    type: ReferenceType;
    quantity: number;
  }[];
}>(CHECK_IF_STOCKS_ARE_UP_TO_DATE_REQUEST);
export const checkIfStocksAreStillUpToDateResponseSaga = createAction(CHECK_IF_STOCKS_ARE_UP_TO_DATE_RESPONSE);
export const fetchReferencesRepairPriceRequestSaga = createAction<{
  references: ReferenceTradingDataRequestDetail[];
}>(GET_REFERENCES_REPAIR_PRICES_REQUEST);
export const fetchReferencesRepairPriceResponseSaga = createAction(GET_REFERENCES_REPAIR_PRICE_RESPONSE);
export const fetchReferencesStocksRequestSaga = createAction<{
  data: ReferenceStockTradingDataRequestDetail[];
  fetchEvenIfExists?: boolean;
}>(GET_REFERENCES_STOCKS_REQUEST);
export const fetchReuseStocksRequestSaga = createAction<{
  referenceNumbers: string[];
  nodeId?: string;
}>(GET_REUSE_STOCKS_REQUEST);
export const fetchReuseStocksResponseSaga = createAction(GET_REUSE_STOCK_RESPONSE);
export const fetchReferencesStocksResponseSaga = createAction(GET_REFERENCES_STOCKS_RESPONSE);
export const fetchReferencesDiscountsRequestSaga = createAction<{
  references: ReferenceTradingDataRequestDetail[];
}>(GET_REFERENCES_DISCOUNTS_REQUEST);
export const fetchReferenceDiscountsResponse = createAction(GET_REFERENCES_DISCOUNTS_RESPONSE);
export const fetchReferenceDiscountsRemoveResponse = createAction(REMOVE_REFERENCES_DISCOUNTS_RESPONSE);
const getNotEmptyArrayOrDefault = <T>(incoming?: Array<T>, old?: Array<T>) =>
  incoming && incoming.length > 0 ? incoming : old;
export const fetchPlatesForReferenceRequestSaga = createAction<{
  vehicleKey: string;
  referenceNumber: string;
}>(GET_PLATES_FOR_REFERENCE_REQUEST);
export const fetchPlatesForReferenceResponseSaga = createAction(GET_PLATES_FOR_REFERENCE_RESPONSE);

function addDHReference(
  reference: DHReferenceLocal,
  references: Map<string, Draft<DHReferenceLocal | NO_DATA>>,
  detailStatus: SEARCH_STATUS,
): void {
  const localRef = getData(references.get(reference.referenceNumber));
  mergeObjectInMap(references, reference.referenceNumber, {
    ...reference,
    // if exists, do not change it
    additionalInformation: getNotEmptyArrayOrDefault(localRef?.additionalInformation, reference.additionalInformation),
    linkedReferences: getNotEmptyArrayOrDefault(localRef?.linkedReferences, reference.linkedReferences),
    alternativeReferences: getNotEmptyArrayOrDefault(localRef?.alternativeReferences, reference.alternativeReferences),
    referencePlateLines: getNotEmptyArrayOrDefault(localRef?.referencePlateLines, reference.referencePlateLines),
    supersessionChainReferences: getNotEmptyArrayOrDefault(
      localRef?.supersessionChainReferences,
      reference.supersessionChainReferences,
    ),
    replacingReferences: getNotEmptyArrayOrDefault(localRef?.replacingReferences, reference.replacingReferences),
    imageKeys: getNotEmptyArrayOrDefault(localRef?.imageKeys, reference.imageKeys),
    detailStatus: detailStatus ?? localRef?.detailStatus,
    isApplicableToCurrentVehicle: localRef?.isApplicableToCurrentVehicle || reference.isApplicableToCurrentVehicle,
    referencePlateLinesStatus:
      localRef?.referencePlateLinesStatus ?? (reference.referencePlateLines?.length ?? 0) > 0 ? FOUND : undefined,
    source: 'dh',
  });
}

function addIAMReference(
  reference: IAMReferenceLocal,
  references: Map<string, Draft<IAMReferenceLocal | NO_DATA>>,
  detailStatus: SEARCH_STATUS,
): void {
  const localRef = getData(references.get(reference.referenceNumber));
  mergeObjectInMap(references, reference.referenceNumber, {
    ...reference,
    // if exists, do not change it
    brand: localRef?.brand ?? reference.brand,
    detailStatus: detailStatus ?? localRef?.detailStatus,
    linkedReferences: getNotEmptyArrayOrDefault(localRef?.linkedReferences, reference.linkedReferences),
    source: 'iam',
  });
}

function getReferencesState(
  state: Draft<ReferencesState>,
  vehicleKey: string | undefined,
): {
  iamReferences: {
    references: Map<string, IAMReferenceLocal | NO_DATA>;
    vehicleReferences: Map<Draft<string>, Draft<IAMReferenceLocal | NO_DATA>>;
  };
  dhReferences: {
    references: Map<string, DHReferenceLocal | NO_DATA>;
    vehicleReferences: Map<Draft<string>, Draft<DHReferenceLocal | NO_DATA>>;
  };
} {
  if (!vehicleKey) {
    return {
      iamReferences: {
        references: state.references.iamReferences.references,
        vehicleReferences: new Map(),
      },
      dhReferences: {
        references: state.references.dhReferences.references,
        vehicleReferences: new Map(),
      },
    };
  }

  const dhVehicleReferences =
    state.references.dhReferences.vehicleReferences.get(vehicleKey) ??
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    state.references.dhReferences.vehicleReferences.set(vehicleKey, new Map()).get(vehicleKey)!;
  const iamVehicleReferences =
    state.references.iamReferences.vehicleReferences.get(vehicleKey) ??
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    state.references.iamReferences.vehicleReferences.set(vehicleKey, new Map()).get(vehicleKey)!;

  return {
    dhReferences: {
      vehicleReferences: dhVehicleReferences,
      references: new Map(),
    },
    iamReferences: {
      vehicleReferences: iamVehicleReferences,
      references: new Map(),
    },
  };
}

const dhReferences: ReferenceCatalog<DHReferenceLocal> = {
  vehicleReferences: new Map<string, Map<string, DHReferenceLocal | NO_DATA>>(),
  references: new Map<string, DHReferenceLocal | NO_DATA>(),
};

const iamReferences: ReferenceCatalog<IAMReferenceLocal> = {
  vehicleReferences: new Map<string, Map<string, IAMReferenceLocal | NO_DATA>>(),
  references: new Map<string, IAMReferenceLocal | NO_DATA>(),
};

// Init state
const initialState: ReferencesState = {
  references: {
    dhReferences,
    iamReferences,
  },
  prices: new Map<string, ReferencePriceType | NO_DATA>(),
  repairPrices: new Map<string, SearchData<string>>(),
  stockInfo: new Map<string, SearchData<ReferenceStock>>(),
  stockUpdateChecks: new Map<string, ReferenceStock[] | typeof LOADING>(),
  referenceDiscounts: new Map<string, SearchData<ReferenceDiscount>>(),
  referenceToReferenceType: new Map<string, ReferenceType>(),
  selectedTab: undefined,
  reuseStock: new Map<string, SearchData<ReuseReference>>(),
  referenceToReferenceSource: new Map<string, ReferenceSource>(),
};

// Saga actions

// Slice
const slice = createSlice({
  name: REFERENCES_NAMESPACE,
  initialState,
  reducers: {
    setInitialState: () => initialState,
    setDHReferences: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string | undefined;
        references: DHReference[];
        detailStatus?: SEARCH_STATUS;
      }>,
    ) => {
      const { vehicleKey, references, detailStatus } = payload;
      const { dhReferences: dhReferenceState } = getReferencesState(state, vehicleKey);
      const referenceState = vehicleKey ? dhReferenceState.vehicleReferences : dhReferenceState.references;
      references.forEach((r) => {
        addDHReference({ ...r, source: 'dh' }, referenceState, detailStatus);
        state.referenceToReferenceType.set(r.referenceNumber, r.type);
      });
    },
    setPlatesForReferenceSearchStatus: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string;
        reference: string;
        status: SEARCH_STATUS;
      }>,
    ) => {
      const { vehicleKey, reference, status } = payload;
      const referenceMap = state.references.dhReferences.vehicleReferences.get(vehicleKey) ?? new Map();
      const curr = getData(referenceMap.get(reference));
      referenceMap.set(reference, {
        ...curr,
        referencePlateLinesStatus: status,
      });
    },
    setPlatesForReference: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string;
        reference: string;
        plates: ReferencePlateLine[];
      }>,
    ) => {
      const { vehicleKey, reference, plates } = payload;
      const referenceMap = state.references.dhReferences.vehicleReferences.get(vehicleKey) ?? new Map();
      const curr = getData(referenceMap.get(reference));
      referenceMap.set(reference, {
        ...curr,
        referencePlateLinesStatus: FOUND,
        referencePlateLines: plates,
      });
    },
    resetReferences: (state) => {
      state.references = initialState.references;
    },
    resetDHReferences: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string;
      }>,
    ) => {
      const { vehicleKey } = payload;
      state.references.dhReferences.vehicleReferences.delete(vehicleKey);
    },
    setIAMReferences: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string | undefined;
        references: IAMReference[] | NO_DATA;
        detailStatus?: SEARCH_STATUS;
      }>,
    ) => {
      const { vehicleKey, references, detailStatus } = payload;
      const { iamReferences: iamReferenceState } = getReferencesState(state, vehicleKey);
      const referenceState = vehicleKey ? iamReferenceState.vehicleReferences : iamReferenceState.references;

      if (references instanceof Array) {
        references?.forEach((r) => {
          addIAMReference({ ...r, source: 'iam', referenceSource: r.referenceSource }, referenceState, detailStatus);
          state.referenceToReferenceType.set(r.referenceNumber, r.type);
          state.referenceToReferenceSource.set(r.referenceNumber, r.referenceSource ?? 'STANDARD');
        });
      }
    },
    setReferencesPriceNoDataStatus: (
      state,
      {
        payload,
      }: PayloadAction<{
        referenceNumbers: string[];
        status: NO_DATA;
      }>,
    ) => {
      const { referenceNumbers, status } = payload;
      referenceNumbers.forEach((ref) => {
        const current = state.prices.get(ref);
        if (hasData(current)) {
          return;
        }
        mergeObjectInMap(state.prices, ref, status);
      });
    },
    setReferencesRepairPriceNoDataStatus: (
      state,
      {
        payload,
      }: PayloadAction<{
        referenceNumbers: string[];
        status: NO_DATA;
      }>,
    ) => {
      const { referenceNumbers, status } = payload;
      referenceNumbers.forEach((ref) => state.repairPrices.set(ref, { searchStatus: status }));
    },
    setReferencesStocksNoDataStatus: (
      state,
      {
        payload,
      }: PayloadAction<{
        references: string[];
        status: SEARCH_STATUS;
      }>,
    ) => {
      const { references, status } = payload;
      references.forEach((ref) => {
        mergeObjectInMap(state.stockInfo, ref, { searchStatus: status });
      });
    },
    setReferencesPriceData: (state, { payload }: PayloadAction<ReferencePrice[]>) => {
      payload.forEach((ref) => {
        const objToMerge =
          !ref.currency && !ref.clientView && !ref.garageView
            ? NOT_FOUND
            : {
                currency: ref.currency,
                clientView: ref.clientView,
                garageView: ref.garageView,
              };
        mergeObjectInMap(state.prices, ref.reference, objToMerge);
      });
    },
    resetReferencesPriceData: (state) => {
      state.prices = initialState.prices;
    },
    setReferencesRepairPriceData: (state, { payload }: PayloadAction<ReferenceRepairPrice[]>) => {
      payload.forEach((ref) => state.repairPrices.set(ref.referenceNumber, { searchStatus: FOUND, data: ref.price }));
    },
    setReferencesStocksData: (state, { payload }: PayloadAction<ReferenceStock[]>) => {
      payload.forEach((newStock) => {
        const isEmpty = newStock.warehouses && newStock.warehouses.length === 0;
        mergeObjectInMap(state.stockInfo, newStock.reference, {
          searchStatus: isEmpty ? NOT_FOUND : FOUND,
          data: newStock,
        });
      });
    },
    setReferencesDiscountsSearchStatus: (
      state,
      { payload }: PayloadAction<{ referenceNumbers: string[]; searchStatus: SEARCH_STATUS }>,
    ) => {
      const { referenceNumbers, searchStatus } = payload;
      referenceNumbers.forEach((r) => {
        state.referenceDiscounts.set(r, { searchStatus });
      });
    },
    setReferencesDiscounts: (state, { payload }: PayloadAction<ReferencesDiscountsResponse>) => {
      const { referenceDiscounts } = payload;
      referenceDiscounts.forEach((discount) => {
        state.referenceDiscounts.set(discount.referenceNumber, { searchStatus: FOUND, data: discount });
      });
    },
    removeReferencesDiscounts: (state, { payload }: PayloadAction<Array<string>>) => {
      payload.forEach((refNumber) => {
        state.referenceDiscounts.delete(refNumber);
      });
    },
    resetReferencesDiscounts: (state) => {
      state.referenceDiscounts = initialState.referenceDiscounts;
    },
    setCrossSellingReferences: (
      state,
      {
        payload,
      }: PayloadAction<{
        crossSellingReferences: CrossSellingGroup[];
        vehicleKey: string | undefined;
        status?: NO_DATA;
      }>,
    ) => {
      const { vehicleKey, crossSellingReferences, status } = payload;
      const { dhReferences: dhReferenceState } = getReferencesState(state, vehicleKey);
      const referenceState = vehicleKey ? dhReferenceState.vehicleReferences : dhReferenceState.references;
      const refNumbers = [...referenceState.keys()];
      refNumbers.forEach((ref) => {
        const localRef = getData(referenceState.get(ref));
        if (localRef) {
          const isRequested = crossSellingReferences.find((cr) => cr.sourceReference === ref);
          const group = crossSellingReferences.find((cr) => cr.sourceReference === ref);
          const newCrossSells = [
            ...new Set((getData(localRef.crossSelling) ?? []).concat(group?.crossSellingReferences ?? [])),
          ];

          const getCrossSellingStatus = () => {
            if (status === LOADING && isRequested) {
              return LOADING;
            }
            if (getData(localRef.crossSelling) || group) {
              return newCrossSells;
            }
            return NOT_FOUND;
          };

          if (status !== LOADING || (status === LOADING && isRequested)) {
            referenceState.set(ref, {
              ...localRef,
              crossSelling: getCrossSellingStatus(),
            });
          }
        }
      });
    },
    setResolveCrossSellingReferencesStatus: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string | undefined;
        status: NO_DATA;
      }>,
    ) => {
      const { status, vehicleKey } = payload;
      const { dhReferences: dhReferenceState } = getReferencesState(state, vehicleKey);
      const referenceState = vehicleKey ? dhReferenceState.vehicleReferences : dhReferenceState.references;
      const refNumbers = [...referenceState.keys()];
      refNumbers.forEach((ref) => {
        const refLocal = getData(referenceState.get(ref));
        if (refLocal?.crossSelling === LOADING) {
          referenceState.set(ref, {
            ...refLocal,
            crossSelling: status,
          });
        }
      });
    },
    setStockUpdateCheck: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: string;
        result: ReferenceStock[] | typeof LOADING;
      }>,
    ) => {
      const { id, result } = payload;
      state.stockUpdateChecks.set(id, result);
    },
    setDHSingleReferenceStatus: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string | undefined;
        reference: string;
        status: NO_DATA;
      }>,
    ) => {
      const { vehicleKey, reference, status } = payload;
      const { dhReferences: dhReferenceState } = getReferencesState(state, vehicleKey);
      const referenceState = vehicleKey ? dhReferenceState.vehicleReferences : dhReferenceState.references;

      if (!hasData(referenceState.get(reference))) {
        referenceState.set(reference, status);
      } else {
        mergeObjectInMap(referenceState, reference, {
          referenceNumber: reference,
          detailStatus: status,
          source: 'dh',
          type: 'STANDARD',
        });
      }
    },
    setReuseStockNoDataStatus: (
      state,
      {
        payload,
      }: PayloadAction<{
        referenceNumbers: string[];
        status: NO_DATA;
      }>,
    ) => {
      const { referenceNumbers, status } = payload;
      referenceNumbers.forEach((ref) => state.reuseStock.set(ref, { searchStatus: status }));
    },
    setReuseStockData: (state, { payload }: PayloadAction<ReuseReference[]>) => {
      payload.forEach((ref) => {
        const hasData =
          (ref.eco && ref.eco.totalQuantity !== 0) ||
          (ref.medium && ref.medium.totalQuantity !== 0) ||
          (ref.premium && ref.premium.totalQuantity !== 0);
        state.reuseStock.set(ref.referenceNumber, {
          searchStatus: hasData ? FOUND : NOT_FOUND,
          data: hasData ? ref : undefined,
        });
      });
    },
    setSelectedTab: (state, action: PayloadAction<LocalTabType>) => {
      state.selectedTab = action.payload;
    },
  },
});

// Actions
export const {
  setInitialState,
  setDHReferences,
  setPlatesForReferenceSearchStatus,
  setPlatesForReference,
  resetReferences,
  resetDHReferences,
  setIAMReferences,
  setReferencesPriceNoDataStatus,
  setReferencesRepairPriceNoDataStatus,
  setReferencesStocksNoDataStatus,
  setReferencesPriceData,
  resetReferencesPriceData,
  setReferencesRepairPriceData,
  setReferencesStocksData,
  setDHSingleReferenceStatus,
  setReferencesDiscountsSearchStatus,
  setReferencesDiscounts,
  setStockUpdateCheck,
  removeReferencesDiscounts,
  resetReferencesDiscounts,
  setCrossSellingReferences,
  setResolveCrossSellingReferencesStatus,
  setReuseStockNoDataStatus,
  setReuseStockData,
  setSelectedTab,
} = slice.actions;

// Getters/Selectors
export const getDHReference = createSelector(
  (state: RootState) => state.references.references.dhReferences.vehicleReferences,
  (state: RootState) => state.references.references.dhReferences.references,
  (
    _state: RootState,
    payload: {
      vehicleKey: string | undefined;
      referenceNumber?: string;
    },
  ) => payload,
  (vehicleReferences, references, payload) => {
    if (!payload.referenceNumber) {
      return undefined;
    }
    return payload.vehicleKey
      ? vehicleReferences.get(payload.vehicleKey)?.get(payload.referenceNumber)
      : references.get(payload.referenceNumber);
  },
);

export const getDHReferences = createSelector(
  (state: RootState) => state.references.references.dhReferences.vehicleReferences,
  (state: RootState) => state.references.references.dhReferences.references,
  (
    _state: RootState,
    payload: {
      vehicleKey: string | undefined;
      referenceNumbers?: string[];
    },
  ) => payload,
  (vehicleReferences, references, payload) => {
    if (!payload.referenceNumbers) {
      return [];
    }
    return payload.vehicleKey
      ? (payload.referenceNumbers
          .map((ref) => vehicleReferences.get(payload.vehicleKey as string)?.get(ref))
          .filter((r) => r) as DHReferenceLocal[])
      : (payload.referenceNumbers.map((ref) => references.get(ref)).filter((r) => r) as DHReferenceLocal[]);
  },
);

export const getDHReferencesMap = createSelector(
  (state: RootState) => state.references.references.dhReferences.vehicleReferences,
  (state: RootState) => state.references.references.dhReferences.references,
  (
    _state: RootState,
    payload: {
      vehicleKey: string | undefined;
      referenceNumbers: string[];
    },
  ) => payload,
  (vehicleReferences, references, payload) => {
    const refToSearchIn = payload.vehicleKey ? vehicleReferences.get(payload.vehicleKey) ?? new Map() : references;
    return payload.referenceNumbers.reduce(
      (acc, next) => acc.set(next, refToSearchIn.get(next)),
      new Map<string, DHReferenceLocal | NO_DATA>(),
    );
  },
);

export const getIAMReference = createSelector(
  (state: RootState) => state.references.references.iamReferences.vehicleReferences,
  (state: RootState) => state.references.references.iamReferences.references,
  (
    _state: RootState,
    payload: {
      vehicleKey: string | undefined;
      referenceNumber?: string;
    },
  ) => payload,
  (vehicleReferences, references, payload) => {
    if (!payload.referenceNumber) {
      return undefined;
    }
    return payload.vehicleKey
      ? vehicleReferences.get(payload.vehicleKey)?.get(payload.referenceNumber)
      : references.get(payload.referenceNumber);
  },
);

export const getIAMReferences = createSelector(
  (state: RootState) => state.references.references.iamReferences.vehicleReferences,
  (state: RootState) => state.references.references.iamReferences.references,
  (
    _state: RootState,
    payload: {
      vehicleKey: string | undefined;
      referenceNumbers?: string[];
    },
  ) => payload,
  (vehicleReferences, references, payload) => {
    if (!payload.referenceNumbers) {
      return [];
    }
    return payload.vehicleKey
      ? (payload.referenceNumbers
          .map((ref) => vehicleReferences.get(payload.vehicleKey as string)?.get(ref))
          .filter((r) => r) as IAMReferenceLocal[])
      : (payload.referenceNumbers.map((ref) => references.get(ref)).filter((r) => r) as IAMReferenceLocal[]);
  },
);

export const getIAMReferencesMap = createSelector(
  (state: RootState) => state.references.references.iamReferences.vehicleReferences,
  (state: RootState) => state.references.references.iamReferences.references,
  (
    _state: RootState,
    payload: {
      vehicleKey: string | undefined;
      referenceNumbers: string[];
    },
  ) => payload,
  (vehicleReferences, references, payload) => {
    const refToSearchIn = payload.vehicleKey ? vehicleReferences.get(payload.vehicleKey) ?? new Map() : references;
    return payload.referenceNumbers.reduce(
      (acc, next) => acc.set(next, refToSearchIn.get(next)),
      new Map<string, IAMReferenceLocal | NO_DATA>(),
    );
  },
);

export const getStockInfo = createSelector(
  (state: RootState, referenceNumber?: string) =>
    referenceNumber ? state.references.stockInfo.get(referenceNumber) : undefined,
  (stockInfo) => stockInfo,
);

export const getIsStockAvailable = createSelector(
  (state: RootState, referenceNumber?: string) =>
    referenceNumber ? state.references.stockInfo.get(referenceNumber)?.searchStatus !== NOT_FOUND : true,
  (isStockAvailable) => isStockAvailable,
);

export const getIsMprAccepted = createSelector(
  (state: RootState) => state.tires.mprValidation,
  (_state: RootState, reference: string) => reference,
  (mprValidation, reference) => {
    const product = mprValidation.data?.products.find((p) => p.reference === reference);
    return product ? product.accepted : undefined;
  },
);

export const getReferencesToStockInfos = createSelector(
  (state: RootState) => state.references.stockInfo,
  (_state: RootState, referenceNumbers: string[]) => referenceNumbers,
  (stockInfo, referenceNumbers) => {
    return referenceNumbers.map((r) => stockInfo.get(r));
  },
);

export const getStockUpdateCheckResult = createSelector(
  (state: RootState) => state.references.stockUpdateChecks,
  (_state: RootState, id?: string) => id,
  (stockUpdateChecks, id) => {
    if (id === undefined) {
      return undefined;
    }
    return stockUpdateChecks.get(id);
  },
);

export const getPrice = createSelector(
  (state: RootState) => state.references.prices,
  (_state: RootState, referenceNumber?: string) => referenceNumber,
  (prices, referenceNumber) => {
    if (!referenceNumber) {
      return undefined;
    }
    return prices.get(referenceNumber);
  },
);

export const getRepairPrice = createSelector(
  (state: RootState) => state.references.repairPrices,
  (_state: RootState, referenceNumber?: string) => referenceNumber,
  (repairPrices, referenceNumber) => {
    if (!referenceNumber) {
      return undefined;
    }
    return repairPrices.get(referenceNumber);
  },
);

export const getLoadedPrices = createSelector(
  (state: RootState) => state.references.prices,
  (_state: RootState, referenceNumbers: string[]) => referenceNumbers,
  (prices, referenceNumbers) => {
    return referenceNumbers.map((reference) => ({
      prices: prices.get(reference),
      reference,
    }));
  },
);

export const getLoadedPricesMap = createSelector(
  (state: RootState) => state.references.prices,
  (_state: RootState, referenceNumbers: string[]) => referenceNumbers,
  (prices, referenceNumbers) => {
    const pricesMap = new Map<string, NO_DATA | ReferencePriceType>();
    referenceNumbers.forEach((reference) => pricesMap.set(reference, prices.get(reference)));
    return pricesMap;
  },
);

export const getPricesMap = createSelector(
  (state: RootState) => state.references.prices,
  (_state: RootState, referenceNumbers: string[]) => referenceNumbers,
  (prices, referenceNumbers) => {
    return referenceNumbers.reduce(
      (acc, next) => acc.set(next, prices.get(next)),
      new Map<string, NO_DATA | ReferencePriceType>(),
    );
  },
);

export const getRepairPricesMap = createSelector(
  (state: RootState) => state.references.repairPrices,
  (_state: RootState, referenceNumbers: string[]) => referenceNumbers,
  (prices, referenceNumbers) => {
    return referenceNumbers.reduce(
      (acc, next) => acc.set(next, prices.get(next)),
      new Map<string, SearchData<string> | undefined>(),
    );
  },
);

export const getStocksMap = createSelector(
  (state: RootState) => state.references.stockInfo,
  (_state: RootState, referenceNumbers: string[]) => referenceNumbers,
  (stocks, referenceNumbers) => {
    return referenceNumbers.reduce(
      (acc, next) => acc.set(next, stocks.get(next)),
      new Map<string, SearchData<ReferenceStock> | undefined>(),
    );
  },
);

export const getReuseStocksMap = createSelector(
  (state: RootState) => state.references.reuseStock,
  (_state: RootState, referenceNumbers: string[]) => referenceNumbers,
  (reuseStock, referenceNumbers) => {
    return referenceNumbers.reduce(
      (acc, next) => acc.set(next, reuseStock.get(next)),
      new Map<string, SearchData<ReuseReference> | undefined>(),
    );
  },
);

export const getReuseStocks = createSelector(
  (state: RootState) => state.references.reuseStock,
  (_state: RootState, referenceNumber: string) => referenceNumber,
  (reuseStock, referenceNumber) => reuseStock.get(referenceNumber),
);

export const getDiscount = createSelector(
  (state: RootState) => state.references.referenceDiscounts,
  (_state: RootState, reference: string) => reference,
  (referenceDiscounts, reference) => referenceDiscounts.get(reference)?.data,
);

export const getDiscounts = createSelector(
  (state: RootState) => state.references.referenceDiscounts,
  (_state: RootState, references: string[]) => references,
  (referenceDiscounts, references) => {
    const discounts = new Map<string, ReferenceDiscount>();
    references.forEach((r) => {
      const disc = referenceDiscounts.get(r);
      if (disc?.data) {
        discounts.set(r, disc.data);
      }
    });
    return discounts;
  },
);

export const getDiscountStatuses = createSelector(
  (state: RootState) => state.references.referenceDiscounts,
  (_state: RootState, references: string[]) => references,
  (referenceDiscounts, references) => {
    const discounts = new Map<string, SEARCH_STATUS>();
    references.forEach((r) => {
      const disc = referenceDiscounts.get(r);
      discounts.set(r, disc?.searchStatus);
    });
    return discounts;
  },
);

export const getReferenceTypes = createSelector(
  (state: RootState) => state.references.referenceToReferenceType,
  (_state: RootState, references: string[]) => references,
  (referenceToReferenceType, references) =>
    references.reduce(
      (acc, next) => acc.set(next, referenceToReferenceType.get(next)),
      new Map<string, ReferenceType | undefined>(),
    ),
);

export const getReferenceSources = createSelector(
  (state: RootState) => state.references.referenceToReferenceSource,
  (_state: RootState, references: string[]) => references,
  (referenceToReferenceSource, references) =>
    references.reduce(
      (acc, next) => acc.set(next, referenceToReferenceSource.get(next)),
      new Map<string, ReferenceSource | undefined>(),
    ),
);

export const getSelectedTab = createSelector(
  (state: RootState) => state.references.selectedTab,
  (selectedTab) => selectedTab,
);

// Export reducer
export default slice.reducer;
