import React, { useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';
import styled from 'styled-components';
import { RootState } from 'app/AppStore';
import {
  getLastSearchVehicleTechnicalData,
  getLastVehicleDetail,
  getVehicleTechnicalDataCategoryL2List,
} from 'domains/catalog/Catalog.store';
import { VehicleTabContentBase } from 'pages/CatalogPage/common';
import VehicleTechnicalDataL2 from 'pages/CatalogPage/IAM/VehicleTechnicalData/VehicleTechnicalDataL2';
import { Flex, PrintButtonRound, Text } from 'UI';
import { isQueryBMM } from 'utils';

const PdfHiddenWrapper = styled.div`
  display: none;
`;

const PdfWrapper = styled(Flex)`
  padding: 20px;
`;

const VehicleTechnicalDataPrintPdf = ({ categoryId }: { categoryId: string | undefined }) => {
  const { t } = useTranslation();
  const categoryL2List = useSelector((state: RootState) => getVehicleTechnicalDataCategoryL2List(state, categoryId));
  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef });
  const technicalData = useSelector((state: RootState) => getLastSearchVehicleTechnicalData(state));
  const currentVehicle = useSelector(getLastVehicleDetail);
  const isBMM = isQueryBMM(currentVehicle?.vehicleKey ?? '');
  const category = technicalData?.find((d) => d.id === categoryId);
  const date = moment().format('DD-MM-YYYY');

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'p') {
        event.preventDefault();
        reactToPrintFn();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    /* eslint-disable-next-line */
  }, []);

  return (
    <>
      <PrintButtonRound onClick={reactToPrintFn} />
      <PdfHiddenWrapper>
        <PdfWrapper direction={'column'} ref={contentRef} gap={15}>
          <div>
            <Text type={'h2'} disableGutter>
              {t('catalog.parts.category.technical_data', 'Technical data')}
            </Text>
            <Text type={'h6_black_45'}>
              <Trans i18nKey={'estimate.pdf.date'} tOptions={{ date }}>
                Date: {{ date }}
              </Trans>
            </Text>
          </div>
          <Flex minWidth={900}>
            {currentVehicle && (
              <VehicleTabContentBase
                result={currentVehicle}
                vehicleImage={currentVehicle.imageUrl}
                isBMM={isBMM}
                isPopover={true}
              />
            )}
          </Flex>
          <Text type={'h6'}>{category?.label ?? ''}</Text>
          <Flex direction={'column'}>
            {categoryL2List?.map((l2) => {
              return (
                <VehicleTechnicalDataL2
                  key={`vehicle-technical_data-category_l2-${l2.id}`}
                  l3Categories={l2.l3List}
                  categoryLabel={l2.label}
                />
              );
            })}
          </Flex>
        </PdfWrapper>
      </PdfHiddenWrapper>
    </>
  );
};

export default VehicleTechnicalDataPrintPdf;
