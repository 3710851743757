/* eslint-disable max-len */
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ReferenceDiscount } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceDiscount';
import {
  Origin,
  ReferenceSource,
  ReferenceType,
} from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/request/GetReferencesStockRequest';
import { CatalogSource } from '@1po/1po-bff-fe-spec/generated/common/vehicle/CatalogSource';
import { matchRoute, ROUTER_CATALOG_DH_L3, ROUTER_PRODUCT } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { trackAppEvent } from 'app/AppTracker';
import { BagIcon, ChevronDownIcon, ChevronUpIcon, TagsIcon } from 'assets/icons';
import { DataContainer } from 'components/DataContainer';
import { calculatePriceAfterDiscount, getDiscountColor, getDiscountTextStyle } from 'components/Discount';
import { FirstHelpPopin } from 'components/Help/FirstHelpPopin';
import Popover from 'components/Popover';
import QuantityModule from 'components/QuantityModule';
import { QuantityModuleUpdateComponentType } from 'components/QuantityModule/QuantityModule';
import { PRICE_WRAPPER_MAX_WIDTH_NARROW, PriceErrorDisplay } from 'components/ReferencePriceSection/PriceErrorDisplay';
import { PriceUnavailable } from 'components/ReferencePriceSection/PriceUnavailable';
import { DeliveryInformationUnavailable } from 'components/ReferenceUnavailableBox/DeliveryInformationUnavailable';
import PrivateComponent from 'composers/PrivateComponent';
import { useIsOilChunkUser } from 'domains/appContext/AppContext.requests';
import { get750ChunkReferences, getChunkReferences } from 'domains/appContext/AppContext.store';
import {
  getBasketOtherSection,
  getBasketVehicles,
  updateReferenceQuantity,
  updateReferenceQuantityRequest,
} from 'domains/basket/Basket.store';
import { removeCartReferenceInCatalog } from 'domains/catalog/Catalog.store';
import { DATAHUB } from 'domains/catalog/Catalog.types';
import { getDiscount, getIsStockAvailable, getPrice, getStockInfo, ReferencePriceType } from 'domains/references';
import { useFetchPrices } from 'domains/references/References.requests';
import { ClientView, GarageView, SparePartsViewType, UserRole } from 'domains/user';
import { GrayedOut } from 'pages/CatalogPage/DH/Product/ProductTab.styled';
import { theme } from 'styles';
import {
  BlackButton,
  ButtonShape,
  Flex,
  GreyButton,
  Icon,
  MarginBox,
  notifyTop,
  Spin,
  Text,
  YellowCartArrowDownButton,
} from 'UI';
import { FOUND, getData, textFormatter } from 'utils';
import {
  TRACKING_EVENT_CART_PART_QUANTITY_ZERO,
  TRACKING_EVENT_PAGE_PRODUCT_PART_DELETION,
  TRACKING_EVENT_PAGE_RESULT_PART_DELETION,
} from 'utils/eventTracker/EventTracker.types';
import { SFlex } from './ReferencePriceSection.styled';
/* eslint-enable max-len */

export const PRICE_WRAPPER_MAX_WIDTH = 200;

export const getDiscountedPrice = (price: number, discount?: number): number => {
  if (!discount) {
    return price;
  } else {
    return price * (1 - discount / 100);
  }
};

const ClientDiscountView = ({
  displayDetailedView,
  setDisplayDetailedView,
  priceData,
  discount,
  isReplacementAvailable,
}: {
  displayDetailedView: boolean;
  setDisplayDetailedView: (display: boolean) => void;
  priceData: ReferencePriceType | undefined;
  discount: ReferenceDiscount | undefined;
  isReplacementAvailable?: boolean;
}) => {
  const { t } = useTranslation();
  const promotionDiscountValue = discount?.discount;

  return (
    <>
      {isReplacementAvailable && (
        <>
          {displayDetailedView ? (
            <Flex direction={'column'}>
              <Flex direction={'row'}>
                <Flex minWidth={159} maxWidth={159}>
                  {promotionDiscountValue && (
                    <Icon
                      IconComponent={TagsIcon}
                      size={16}
                      mt={2}
                      mr={5}
                      color={getDiscountColor(discount)}
                      noPointer
                    />
                  )}
                  <Text type={'light_12_black_65'} dataCy={'discount-promotion'}>
                    {t('common.discount.including_discount', 'Including {{discount_in_percentage}} discount', {
                      discount_in_percentage: textFormatter.formatPercentDecimal(
                        Number(priceData?.garageView?.discountRate) || 0,
                      ),
                    })}
                  </Text>
                </Flex>
                <Icon
                  IconComponent={ChevronUpIcon}
                  size={16}
                  onClick={() => setDisplayDetailedView(false)}
                  hoverFill={theme.color.grey20}
                  mt={1}
                  ml={5}
                />
              </Flex>
              {promotionDiscountValue && (
                <Flex>
                  <Text type={'light_12'} displayStyle={getDiscountTextStyle(discount)}>
                    {textFormatter.formatPercentDecimal(promotionDiscountValue)}
                  </Text>
                  <MarginBox mr={5} />
                  <Text type={'light_12'} displayStyle={getDiscountTextStyle(discount)}>
                    {t('common.discount.additional_discount', 'Additional discount')}
                  </Text>
                </Flex>
              )}
              {priceData && (
                <Text type={'light_12_black_65'} dataCy={'price-vat-excl'}>
                  {t('common.price.vat_exclude_price', '{{vat_exclude_price}} VAT. Excl', {
                    vat_exclude_price: textFormatter.formatCurrency(
                      calculatePriceAfterDiscount(discount, priceData, false),
                      priceData.currency,
                    ),
                  })}
                </Text>
              )}
            </Flex>
          ) : (
            <Flex direction={'column'}>
              <Flex direction={'row'}>
                <Flex minWidth={159} maxWidth={159}>
                  {promotionDiscountValue && (
                    <Icon
                      IconComponent={TagsIcon}
                      size={16}
                      mt={2}
                      mr={5}
                      color={getDiscountColor(discount)}
                      noPointer
                    />
                  )}
                  <Text type={'light_12_black_65'}>
                    {t('common.discount.discount_code', 'DiscountCode: {{discount_code}}', {
                      discount_code: priceData?.clientView?.discountCode,
                    })}
                  </Text>
                </Flex>
                <Icon
                  IconComponent={ChevronDownIcon}
                  size={16}
                  hoverFill={theme.color.grey20}
                  onClick={() => setDisplayDetailedView(true)}
                  mt={1}
                  ml={5}
                />
              </Flex>
              <MarginBox mt={promotionDiscountValue ? 40 : 20} />
            </Flex>
          )}
        </>
      )}
    </>
  );
};

interface ReferenceCardPriceWrapperProps {
  referenceNumber: string;
  sparePartsView: SparePartsViewType;
  handleAddToCartClick: () => void;
  vehicleKey: string | undefined;
  referenceType: ReferenceType;
  catalogSource: CatalogSource;
  align?: 'left' | 'right';
  isApplicableToCurrentVehicle?: boolean;
  hideDiscountSection?: boolean;
  narrow?: boolean;
  showFirstHelp?: boolean;
  useCompactView?: boolean;
  availableForOrder?: boolean;
  origin?: Origin;
  supplierCode?: string;
  referenceSource?: ReferenceSource;
  enabled?: boolean;
  isMKTP?: boolean;
  clientDiscount?: number;
  withIcon?: boolean;
  buttonSize?: 'small' | 'middle' | 'large';
  buttonShape?: ButtonShape;
  linkedReferences?: string[];
  isReplacementAvailable?: boolean;
}

export const ReferencePriceSection = React.memo(function ReferenceCardPriceWrapper({
  referenceNumber,
  sparePartsView,
  handleAddToCartClick,
  isApplicableToCurrentVehicle = true,
  vehicleKey,
  align = 'right',
  hideDiscountSection,
  catalogSource,
  referenceType,
  narrow,
  showFirstHelp = false,
  useCompactView = false,
  availableForOrder = true,
  origin,
  supplierCode,
  referenceSource,
  enabled = true,
  isMKTP = false,
  clientDiscount,
  withIcon = false,
  buttonSize = 'middle',
  buttonShape = 'default',
  linkedReferences = undefined,
  isReplacementAvailable,
}: ReferenceCardPriceWrapperProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const vehicles = useSelector(getBasketVehicles);
  const location = useLocation();
  const otherSection = useSelector(getBasketOtherSection);
  const price = useSelector((state: RootState) => getPrice(state, referenceNumber));
  const stock = useSelector((state: RootState) => getStockInfo(state, referenceNumber));
  const maxQuantity = stock?.data?.maxConfirmedQuantity;
  const chunkReferences = useSelector(getChunkReferences);
  const chunk750References = useSelector(get750ChunkReferences);
  const isOilUser = useIsOilChunkUser();
  const referenceDiscount = useSelector((state: RootState) => getDiscount(state, referenceNumber));
  const priceData = getData(price);
  const referencesOfCurrentVeh = useMemo(
    () => vehicles.find((vehicle) => vehicle.vehicleDetail.vehicleKey === vehicleKey)?.references,
    [vehicleKey, vehicles],
  );
  const [displayDetailedView, setDisplayDetailedView] = useState<boolean>(false);
  const isTire = referenceType === ('TIRE' as ReferenceType);

  const isApplicableOrInCurrVehCart = useCallback(() => {
    if (!vehicleKey) return false;
    return (
      isApplicableToCurrentVehicle || referencesOfCurrentVeh?.find((ref) => ref.referenceNumber === referenceNumber)
    );
  }, [isApplicableToCurrentVehicle, referenceNumber, referencesOfCurrentVeh, vehicleKey]);
  const basketReferences = isApplicableOrInCurrVehCart() ? referencesOfCurrentVeh : otherSection?.references;
  const currentBasketReference = basketReferences?.find((ref) => ref.referenceNumber === referenceNumber);
  const refQuantity = currentBasketReference?.quantity as number;
  const stockAvailable = useSelector((state: RootState) => getIsStockAvailable(state, referenceNumber));
  const availableInUserCountry = catalogSource === DATAHUB ? stockAvailable : true;
  useFetchPrices([referenceNumber, ...(linkedReferences ?? [])], vehicleKey);

  const garagePriceVatExcluded = calculatePriceAfterDiscount(referenceDiscount, priceData, false);
  const recommendedPriceVatExcluded = Number(priceData?.clientView?.recommendedPriceVatExcluded);
  const clientPriceVatExcluded = getDiscountedPrice(recommendedPriceVatExcluded, clientDiscount);
  const recommendedPriceVatIncluded = Number(priceData?.clientView?.recommendedPriceVatIncluded);
  const clientPriceVatIncluded = getDiscountedPrice(recommendedPriceVatIncluded, clientDiscount);
  const vatExcludedMargin = clientPriceVatExcluded - garagePriceVatExcluded;
  const getChunk = () => {
    if (stock?.data?.warehouses.some((wh) => wh.type === 'LOCAL')) {
      return 1;
    }
    return isOilUser ? chunkReferences.get(referenceNumber) : undefined;
  };

  const quantityCallback = useCallback(
    (quantity: number, type?: QuantityModuleUpdateComponentType) => {
      if (quantity === 0) {
        dispatch(
          removeCartReferenceInCatalog({
            vehicleKey: isApplicableOrInCurrVehCart() ? vehicleKey : undefined,
            basketReferenceType: isApplicableOrInCurrVehCart() ? 'VEHICLE' : 'OTHER',
            referenceNumber,
            referenceType,
            origin,
            supplierCode,
            referenceSource: referenceSource ?? 'STANDARD',
          }),
        );
        if (type === 'INPUT') {
          trackAppEvent(TRACKING_EVENT_CART_PART_QUANTITY_ZERO);
        } else {
          trackAppEvent(
            matchRoute(location.pathname, ROUTER_PRODUCT)
              ? TRACKING_EVENT_PAGE_PRODUCT_PART_DELETION
              : TRACKING_EVENT_PAGE_RESULT_PART_DELETION,
          );
        }
      } else {
        dispatch(
          updateReferenceQuantityRequest({
            basketReferenceType: isApplicableOrInCurrVehCart() ? 'VEHICLE' : 'OTHER',
            vehicleKey: isApplicableOrInCurrVehCart() ? vehicleKey : undefined,
            externalBasketId: undefined,
            newQuantity: quantity,
            referenceNumber,
            origin,
            supplierCode,
            referenceSource,
            referenceType,
          }),
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, isApplicableOrInCurrVehCart, location.pathname, referenceNumber, vehicleKey],
  );

  const onMaxValueOverflow = useCallback(() => {
    if (stock?.data?.maxConfirmedQuantity !== undefined) {
      dispatch(
        updateReferenceQuantity({
          vehicleKey,
          referenceNumber,
          newQuantity: stock?.data?.maxConfirmedQuantity,
          externalBasketId: undefined,
        }),
      );
    }
    notifyTop(
      'info',
      t(
        'catalog.reference.stock.notification.parts_unavailable',
        'Sorry, Looks like the desired number of parts is currently unavailable.',
      ),
    );
  }, [dispatch, referenceNumber, stock?.data?.maxConfirmedQuantity, t, vehicleKey]);

  const getMaxWidth = () => {
    if (useCompactView) {
      return 'initial';
    }
    return narrow ? PRICE_WRAPPER_MAX_WIDTH_NARROW : PRICE_WRAPPER_MAX_WIDTH;
  };

  const renderGaragePrice = () => {
    if (!priceData) {
      return <></>;
    }

    const renderPrice = () => (
      <Wrapper>
        <Flex direction={'column'}>
          <Text type={'h5_bold'} displayStyle={getDiscountTextStyle(referenceDiscount)} dataCy={'price-vat-excl'}>
            {t('common.price.vat_exclude_price', '{{vat_exclude_price}} VAT. Excl', {
              vat_exclude_price: textFormatter.formatCurrency(garagePriceVatExcluded, priceData.currency),
            })}
          </Text>
          {referenceDiscount && (
            <Text type={'light_12_black_65'} decoration={'line-through'}>
              {t('common.price.vat_exclude_price', '{{vat_exclude_price}} VAT. Excl', {
                vat_exclude_price: textFormatter.formatCurrency(
                  Number(priceData.garageView?.vatExcludedPrice),
                  priceData.currency,
                ),
              })}
            </Text>
          )}
          {!isMKTP && (
            <Text type={'light_12_black_65'} dataCy={'price-vat-incl'}>
              {t('common.price.or_vat_include_price', 'or {{vat_include_price}} VAT. Incl', {
                vat_include_price: textFormatter.formatCurrency(
                  calculatePriceAfterDiscount(referenceDiscount, priceData, true),
                  priceData.currency,
                ),
              })}
            </Text>
          )}
        </Flex>
      </Wrapper>
    );
    if (useCompactView) {
      return (
        <Wrapper>
          <Popover
            content={
              <SFlex direction={'column'}>
                <Text type={'light_12'}>
                  {t('common.price.vat_exclude_margin', '{{vat_exclude_margin}} VAT. Excl Margin', {
                    vat_exclude_margin: textFormatter.formatCurrency(vatExcludedMargin, priceData.currency),
                  })}
                </Text>
                <Text type={'light_12'}>
                  {t('common.discount.including_discount', 'Including {{discount_in_percentage}} discount', {
                    discount_in_percentage: textFormatter.formatPercentDecimal(
                      Number(priceData.garageView?.discountRate) || 0,
                    ),
                  })}
                </Text>
                {referenceDiscount && (
                  <Text type={'light_12'} displayStyle={getDiscountTextStyle(referenceDiscount)}>
                    {`${referenceDiscount.discount}% ${t(
                      'common.discount.additional_discount',
                      'Additional discount',
                    )}`}
                  </Text>
                )}
              </SFlex>
            }
            arrowColor={theme.color.white}
            placement={'top'}
          >
            {renderPrice()}
          </Popover>
        </Wrapper>
      );
    } else {
      return renderPrice();
    }
  };

  const Wrapper = isReplacementAvailable ? GrayedOut : React.Fragment;

  const renderClientPrice = () => {
    if (!priceData) {
      return <></>;
    }

    const renderPrice = () => {
      return (
        <Wrapper>
          <Flex direction={'column'}>
            {isTire && <Text type={'light_12_black_85'}>{t('common.price.recommended_pcl', 'Recommended PCL')}</Text>}
            <Text type={'h5_bold'} dataCy={'price-vat-incl'}>
              {t('common.price.vat_exclude_price', '{{vat_exclude_price}} VAT. Excl', {
                vat_exclude_price: textFormatter.formatCurrency(clientPriceVatExcluded, priceData?.currency ?? ''),
              })}
            </Text>
            {!isMKTP && (
              <Text type={'light_12_black_65'} dataCy={'price-vat-excl'}>
                {t('common.price.or_vat_include_price', 'or {{vat_include_price}} VAT. Incl', {
                  vat_include_price: textFormatter.formatCurrency(clientPriceVatIncluded, priceData?.currency ?? ''),
                })}
              </Text>
            )}
          </Flex>
        </Wrapper>
      );
    };
    if (useCompactView) {
      return (
        <Wrapper>
          <Popover
            content={
              <SFlex direction={'column'}>
                <Text type={'light_12'}>
                  {t('common.discount.including_discount', 'Including {{discount_in_percentage}} discount', {
                    discount_in_percentage: textFormatter.formatPercentDecimal(
                      Number(priceData.garageView?.discountRate) || 0,
                    ),
                  })}
                </Text>
                {referenceDiscount && (
                  <Text type={'light_12'} displayStyle={getDiscountTextStyle(referenceDiscount)}>
                    {`${referenceDiscount.discount}% ${t(
                      'common.discount.additional_discount',
                      'Additional discount',
                    )}`}
                  </Text>
                )}
                <Text type={'light_12'}>
                  {t('common.price.vat_exclude_price', '{{vat_exclude_price}} VAT. Excl', {
                    vat_exclude_price: textFormatter.formatCurrency(
                      calculatePriceAfterDiscount(referenceDiscount, priceData, false),
                      priceData.currency,
                    ),
                  })}
                </Text>
              </SFlex>
            }
            arrowColor={theme.color.white}
            placement={'top'}
          >
            <Flex>
              {renderPrice()}
              {referenceDiscount && (
                <Icon IconComponent={TagsIcon} size={16} mt={6} ml={10} color={getDiscountColor(referenceDiscount)} />
              )}
            </Flex>
          </Popover>
        </Wrapper>
      );
    } else {
      return renderPrice();
    }
  };

  const renderButtons = () => {
    if (useCompactView) {
      return (
        <YellowCartArrowDownButton
          onClick={() => {
            handleAddToCartClick();
          }}
          round
          size={24}
          boxSize={'smd'}
        />
      );
    }
    if (!isReplacementAvailable) {
      if (enabled) {
        return (
          <BlackButton
            size={buttonSize}
            onClick={() => {
              handleAddToCartClick();
            }}
            stretch
            dataCy={`add to cart ref-${referenceNumber}`}
            shape={buttonShape}
          >
            <Flex align={'center'} justify={'center'} gap={5}>
              {withIcon && <Icon IconComponent={BagIcon} size={20} />}
              {t('cart.action.add_to_cart', 'Add to cart')}
            </Flex>
          </BlackButton>
        );
      }
      return (
        <GreyButton
          size={buttonSize}
          onClick={() => {
            handleAddToCartClick();
          }}
          stretch
          dataCy={`add to cart ref-${referenceNumber}`}
          shape={buttonShape}
        >
          <Flex align={'center'} justify={'center'} gap={5}>
            {withIcon && <Icon IconComponent={BagIcon} size={20} />}
            {t('cart.action.add_to_cart', 'Add to cart')}
          </Flex>
        </GreyButton>
      );
    }
  };

  return (
    <PrivateComponent
      requiredRights={[UserRole.COMMAND, UserRole.CONNECT_COMMANDE]}
      render={() => (
        <>
          {availableInUserCountry ? (
            <DataContainer
              data={price}
              NotFound={() => <PriceUnavailable narrow={narrow} />}
              ErrorState={() => <PriceErrorDisplay narrow={narrow} />}
              Loading={() => <Spin size={'small'} />}
            >
              <Flex
                direction={useCompactView ? 'row' : 'column'}
                maxWidth={getMaxWidth()}
                justify={align === 'left' ? 'flex-start' : 'flex-end'}
                align={useCompactView ? 'center' : 'inherit'}
              >
                <Flex size={1} direction={'column'} align={align === 'left' ? 'flex-start' : 'flex-end'}>
                  {sparePartsView === ClientView && (
                    <Flex minHeight={align === 'left' ? 0 : 86} size={1} direction={'column'} justify={'flex-start'}>
                      {renderClientPrice()}
                      {!useCompactView && !isMKTP && (
                        <Wrapper>
                          <ClientDiscountView
                            displayDetailedView={displayDetailedView}
                            setDisplayDetailedView={setDisplayDetailedView}
                            priceData={priceData}
                            discount={referenceDiscount}
                            isReplacementAvailable={isReplacementAvailable}
                          />
                          {narrow && !referenceDiscount && <MarginBox mt={20} />}
                        </Wrapper>
                      )}
                    </Flex>
                  )}
                  {sparePartsView === GarageView && priceData?.garageView && (
                    <Flex
                      minHeight={align === 'left' ? 0 : 86}
                      size={1}
                      direction={'column'}
                      justify={useCompactView ? 'center' : 'flex-start'}
                    >
                      {renderGaragePrice()}
                      {!useCompactView && (
                        <>
                          {!hideDiscountSection ? (
                            <>
                              <Text type={'light_12_black_65'} dataCy={'margin-vat-excl'}>
                                {t('common.price.vat_exclude_margin', '{{vat_exclude_margin}} VAT. Excl Margin', {
                                  vat_exclude_margin: textFormatter.formatCurrency(
                                    vatExcludedMargin,
                                    priceData.currency,
                                  ),
                                })}
                              </Text>
                              <Text type={'light_12_black_65'}>
                                {t(
                                  'common.discount.including_discount',
                                  'Including {{discount_in_percentage}} discount',
                                  {
                                    discount_in_percentage: textFormatter.formatPercentDecimal(
                                      Number(priceData.garageView?.discountRate) || 0,
                                    ),
                                  },
                                )}
                              </Text>
                            </>
                          ) : (
                            <MarginBox mt={40} />
                          )}
                          {narrow && !referenceDiscount && <MarginBox mt={20} />}
                        </>
                      )}
                    </Flex>
                  )}
                </Flex>
                {useCompactView && <MarginBox ml={15} />}
                <Flex direction={'row'}>
                  {availableForOrder && (
                    <>
                      {currentBasketReference ? (
                        <QuantityModule
                          value={refQuantity}
                          onChange={quantityCallback}
                          showDelete={true}
                          maxValue={maxQuantity}
                          onMaxValueOverflow={onMaxValueOverflow}
                          chunk={getChunk()}
                          isMin750Chunk={isOilUser && chunk750References.includes(referenceNumber)}
                          size={buttonSize}
                        />
                      ) : (
                        <>
                          {(catalogSource === DATAHUB || stock?.searchStatus === FOUND) && (
                            <PrivateComponent
                              render={() => (
                                <Flex
                                  minWidth={buttonSize === 'large' ? 250 : 180}
                                  maxWidth={buttonSize === 'large' ? 250 : 180}
                                  size={0}
                                  direction={'column'}
                                  align={'flex-end'}
                                >
                                  <FirstHelpPopin
                                    streamId={ROUTER_CATALOG_DH_L3}
                                    popinId={`${ROUTER_CATALOG_DH_L3}_add_to_cart`}
                                    placement={'left'}
                                    skip={!showFirstHelp}
                                  >
                                    {!isReplacementAvailable && renderButtons()}
                                  </FirstHelpPopin>
                                </Flex>
                              )}
                              requiredRights={[UserRole.COMMAND, UserRole.CONNECT_COMMANDE]}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </Flex>
              </Flex>
            </DataContainer>
          ) : (
            <DeliveryInformationUnavailable />
          )}
        </>
      )}
    />
  );
});
